<style scoped>
.qc-list {
  padding: 5px 0;
  cursor: pointer;
}
.qc-list-active {
  background-color: #4285f4;
}
</style>
<template>
  <div>
    <h4 class="workplatform-title m-t-10 m-b-10">客户列表</h4>
    <Row
      :gutter="8"
      class="m-t-10"
    >
      <i-col span="18">
        <Input
          v-model.trim="query.keywords"
          size="small"
          placeholder="客户名/品牌名"
        />
      </i-col>
      <i-col span="6">
        <Button
          long
          size="small"
          type="primary"
          icon="ios-search"
          @click="handleSearch"
        >搜索</Button>
      </i-col>
    </Row>
    <div class="m-t-10">
      <Row class="table-title">
        <i-col
          span="12"
          class="p-l-5"
        >客户名称</i-col>
        <i-col
          span="12"
          class="text-right p-r-5"
        >品牌</i-col>
      </Row>
      <Row
        v-for="(item,index) in list"
        class="qc-list"
        :key="index"
        :class="[(index%2 === 0)?'table-row-1':'table-row-2', (item.customerId===selectedCustomerId&&item.brandId===selectedBrandId)?'qc-list-active':'']"
      >
        <i-col span="24">
          <div @click="handleChooseItem(item)">
            <Row>
              <i-col
                span="12"
                class="p-l-5"
              >{{item.customerName}}</i-col>
              <i-col
                span="12"
                class="text-right p-r-5"
              >{{item.brandName}}</i-col>
            </Row>
          </div>
        </i-col>
      </Row>
      <div class="paging_style">
        <Page
          size="small"
          :total="total"
          :page-size="query.pageSize"
          show-total
          show-elevator
          :current="query.pageNumber"
          @on-change="handlePageChange"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: null,
        keywords: ''
      },
      list: [
        { customerName: '青岛啤酒', customerId: 1, brandName: 'xx品牌', brandId: 1 },
        { customerName: '青岛啤酒', customerId: 2, brandName: 'xx品牌', brandId: 2 },
        { customerName: '青岛啤酒', customerId: 3, brandName: 'xx品牌', brandId: 3 },
        { customerName: '青岛啤酒', customerId: 4, brandName: 'xx品牌', brandId: 4 }
      ],
      total: 4,
      selectedCustomerId: null,
      selectedBrandId: null
    }
  },
  created () {

  },
  methods: {
    initData () {

    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleChooseItem (parmas) {
      this.selectedCustomerId = parmas.customerId
      this.selectedBrandId = parmas.brandId
    }
  }
}
</script>
